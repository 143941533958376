import React from "react";
import "./App.css";
import Login from "./views/login";
import MobileLogin from "./views/MobileLogin.js";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home.js";
import MobileGpt from "./views/MobileGpt.jsx";
import Gpt from "./views/Gpt.jsx";
import Fire from "./firebase/register.js";
import Firelogin from "./firebase/login.js";
import Register from "./firebase/register.js";

const App = () => {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Login />} />
        <Route path={"/home"} element={<Home />} />
        <Route path={"/Gpt"} element={<Gpt />} />
        <Route path={"/MobileGpt"} element={<MobileGpt />} />
        <Route path={"/fire"} element={<Fire />} />
        <Route path={"/login"} element={<Firelogin />} />
        <Route path={"/register"} element={<Register />} />
      </Routes>
    </>
  );
};

export default App;
