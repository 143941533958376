import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { auth } from "../firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/login.css";

const Login = () => {
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      nav("/Gpt");
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "top-center",
      });
    }
  };

  return (
    <div className="main">
      <ToastContainer />
      <div class="left_area">
        <div className="Login">
          <div className="id-password-form">
            <h1> Welcome Yes, Bot </h1>
            <h2> 양전자 시스템 YES Bot에 오신걸 환영합니다 </h2>
            <h3> 발급받은 ID와 비밀번호를 입력해주세요 </h3>

            <form onSubmit={handleSubmit}>
              <p class="input_title">이메일</p>
              <input
                className="id-input"
                placeholder="이메일을 입력해주세요"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <p class="input_title2">비밀번호</p>
              <input
                className="password-input"
                placeholder="비밀번호를 입력해주세요"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <button type="submit" className="submit-button">
                Login
              </button>
              <h4>
                {" "}
                혹시 YESBOT이 처음이신가요? <a href="/register">Register</a>
              </h4>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
