import "../assets/Home.css";
import { Search } from "../components/YjSearch";

function Home() {
  let posts = "무엇을 도와드릴까요??";

  return (
    <div className="App">
      <div className="black-nav">
        <div style={{ cllor: "white", fontSize: "50px" }}>
          {" "}
          양전자 인공지능 개발 Project{" "}
        </div>
      </div>

      <h1> {posts} </h1>
      <h4> 질문은 최대한 자세하게 해주세요. </h4>
      <h5> 예시 : Linear Alarm Code 'u'가 떴는데 어떻게 해아할까요?? </h5>
      <Search />
    </div>
  );
}

export default Home;
