
import React, { useState } from 'react';
import axios from 'axios';
import './Search.css';

const Search = () => {

  const [searchInput, setSearchInput] = useState('');
  const handleSubmitClick = () =>{
    axios.get("http://localhost:8080/ping", {
      params: {
        chat: searchInput
      }
    }).then(res => {
 
      console.log(res.data);
 
    })
  }
  
  return (
    <div className='search'>
          <input type='text' maxLength='20' className='search_input' name='search' placeholder='검색어를 입력해주세요.'
            onChange={(event) => {
              setSearchInput(event.currentTarget.value);
            }}
          />
          <button type='submit' className='serach_submit' onClick={handleSubmitClick} >
            검색
            </button>
    
    </div>
  )
}

export default Search;


