// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBAaZVJRmX9LktEC9haIpO7tFoGT2zfJh4",
  authDomain: "yesbot-1022f.firebaseapp.com",
  projectId: "yesbot-1022f",
  storageBucket: "yesbot-1022f.appspot.com",
  messagingSenderId: "1041724020904",
  appId: "1:1041724020904:web:47a8c24ba79f8303888a9d",
  measurementId: "G-2HGDH593L0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();
export const db = getFirestore(app);
export default app;
